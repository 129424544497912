<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";

import {VueEditor} from "vue2-editor";
import axios from "axios";
import {accountMutations, accountStore} from "@/state/modules/accountStoreHelper";
import {allPerposeStore} from "@/state/modules/allPerposeStoreHelper";
import _ from "lodash";
import {msgConfirmOptCenter} from "@/components/globalFunctions";
import {loggedInfoObject} from "@/state/helpers";

export default {
  page: {
    title: "공지사항 작성",
    meta: [
      {
        name: "공지사항 작성",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    VueEditor,
  },

  data() {
    return {
      title: "공지사항 작성",
      newsTitle: "",
      newsWriter: "",
      newsContent: "",
      isLeaveSite: true,
      imageUrl: "",
      tmpCategorySrl: 47,
      fileSrlList: [],
      publicStatus: "SECRET"
    };
  },

  computed: {
    ...accountStore,
    ...allPerposeStore,
    ...loggedInfoObject
  },

  watch: {
    newsTitle: function () {
      if (this.newsTitle !== "") {
        this.isLeaveSite = false
      }
    },
    newsWriter: function () {
      if (this.newsWriter !== "") {
        this.isLeaveSite = false
      }
    },
    newsContent: function () {
      if (this.newsContent !== "") {
        this.isLeaveSite = false
      }
    }
  },

  mounted() {
    this.title = this.$route.query.title

    if (this.$route.query.status === "edit") {
      this.newsTitle = _.cloneDeep(this.generalRowInfo.title)
      this.newsWriter = _.cloneDeep(this.generalRowInfo.nick_name)
      this.newsContent = _.cloneDeep(this.generalRowInfo.content)
      this.publicStatus = _.cloneDeep(this.generalRowInfo.status)
    }

    window.addEventListener('beforeunload', this.unLoadEvent)
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.unLoadEvent)
  },

  beforeRouteLeave(to, from, next) {
    if (this.isLeaveSite) {
      next()
    } else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않습니다.')) {
      next();
    }
  },

  methods: {
    ...accountMutations,

    unLoadEvent(event) {
      console.log("this.isLeaveSite :", this.isLeaveSite)
      if (this.isLeaveSite) return;

      event.preventDefault();
      event.returnValue = '';
    },

    async addNewsImage(file, Editor, cursorLocation) {
      console.log("AddNewsImage", file)

      let fd = new FormData
      fd.append("file", file)

      await this.newsImageUpload(fd)

      // 아래 문장이 에디터 안으로 이미지 보여주는 것
      // https://github.com/davidroyer/vue2editor-images/blob/master/src/App.vue
      // https://www.npmjs.com/package/vue2-editor
      await Editor.insertEmbed(cursorLocation, 'image', this.imageUrl)
    },

    async newsImageUpload(fd) {
      let url = `/api/file/admin/upload/qa/${this.logged_info.mart_srl}`
      console.log(url)

      await axios.post(url, fd)
          .then((res) => {
            console.log(res.data)
            this.imageUrl = "/" + res.data.file_info.uploaded_filename
            this.fileSrlList.push(res.data.file_info.file_srl)
          })
          .catch((err) => {
            console.log("newsImageUpload Error :", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    saveNewsContent() {
      let url = `api/post/insert`

      let newsData = {
        market_srl: Number(this.logged_info.mart_srl),
        phone: this.logged_info.phone,
        doc_phone: this.logged_info.phone,
        title: this.newsTitle,
        nick_name: this.logged_info.mart_name,
        content: this.newsContent,
        status: "PUBLIC"
      }

      console.log(url, newsData)

      axios.post(
          url,
          newsData,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then(async (res) => {
            console.log(res.data)

            if (res.data.message === 'success') {
              this.isLeaveSite = true
              for (let i = this.fileSrlList.length - 1; i >= 0; i--) {
                await this.updateFileDocumentSrl(this.fileSrlList[i], res.data.post_info.document_srl)
              }
              await this.$router.go(-1)
            }
          })
          .catch((err) => {
            console.log("saveNewsContent Error :", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    async updateFileDocumentSrl(fileSrl, val) {
      let url = `api/file/admin/update`

      let fileUpdateData = {
        file_srl: fileSrl,
        document_srl: val
      }

      await axios.put(
          url,
          fileUpdateData,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then((res) => {
            console.log(res.data)
          })
          .catch((err) => {
            console.log("updateFileDocumentSrl Error :", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    async editNewsContent() {
      let url = `api/post/update`

      let newsEditData = {
        document_srl: parseInt(this.generalRowInfo.document_srl),
        member_srl: parseInt(this.accountVuexInfo.member_srl),
        module_srl: parseInt(this.$route.query.type),
        category_srl: parseInt(this.tmpCategorySrl),
        title: this.newsTitle,
        nick_name: this.newsWriter,
        content: this.newsContent,
        status: this.publicStatus
      }

      await axios.put(
          url,
          newsEditData,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then(async (res) => {
            console.log(res.data)
            if (res.data.message === 'success') {
              this.isLeaveSite = true
              for (let i = this.fileSrlList.length - 1; i >= 0; i--) {
                await this.updateFileDocumentSrl(this.fileSrlList[i], res.data.post_info.document_srl)
              }
              await this.$router.go(-1)
            }
          })
          .catch((err) => {
            console.log("editNewsContent Error :", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    deleteNewsContent() {
      let url = `api/post/delete/${this.generalRowInfo.document_srl}`
      this.$bvModal.msgBoxConfirm(`공지사항를 삭제하시겠습니까?\n삭제된 공지사항는 복구할 수 없습니다.`, msgConfirmOptCenter)
          .then(async (sel) => {
            if (sel) {
              console.log("sel", sel)

              axios.delete(
                  url,
                  {
                    headers: {
                      accessToken: this.accountVuexInfo.accessToken,
                      refreshToken: this.accountVuexInfo.refreshToken
                    },
                  })
                  .then((res) => {
                    console.log(res.data)
                    if (res.data.message === "게시글 삭제에 성공하였습니다.") {
                      this.isLeaveSite = true
                      this.$router.go(-1)
                    }
                  })
                  .catch((err) => {
                    console.log("deleteNewsContent Error :", err)
                    if (!_.isEmpty(err.response)) {
                      if (err.response.status === 401) {
                        this.setCheckAuth(false)
                      }
                    }
                  })
            }
          })
    },

    updateInput(event, val) {
      if (Number(val) === 0) {
        this.newsTitle = event.target.value
      } else if (Number(val) === 1) {
        this.newsWriter = event.target.value
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="`Q&A 작성`"/>

    <div class="card">

      <div class="card-header">
                <div v-if="false" class="row mb-3">
                  <label class="col-md-2 col-form-label">카테고리</label>
                  <div class="col-md-3">
                    <select class="form-select">
                      <option>Select</option>
                      <option>Large select</option>
                      <option>Small select</option>
                    </select>
                  </div>
                </div>

        <div class="row">
          <label class="col-md-2 col-form-label">제목</label>
          <div class="col-md-6">
            <input class="form-control" :value="newsTitle" @input="updateInput($event, 0)">
          </div>
        </div>

                <div v-if="false" class="row mb-3">
                  <label class="col-md-2 col-form-label">작성자</label>
                  <div class="col-md-6">
                    <input class="form-control" :value="newsWriter" @input="updateInput($event, 1)">
                  </div>
                </div>

        <div v-if="false" class="row">
          <label class="col-md-2 col-form-label">상태</label>
          <div class="col-md-6 d-flex align-items-center">
            <div class="form-check me-3">
              <input
                  class="form-check-input"
                  type="radio"
                  name="formRadios"
                  id="formRadios1"
                  value="SECRET"
                  v-model="publicStatus"
              />
              <label class="form-check-label" for="formRadios1">
                비공개
              </label>
            </div>
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  name="formRadios"
                  id="formRadios2"
                  value="PUBLIC"
                  v-model="publicStatus"
              />
              <label class="form-check-label" for="formRadios2">
                공개
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <label class="col-md-2 mb-1 col-form-label">질문 작성</label>
        <vue-editor
            v-model="newsContent"
            useCustomImageHandler
            @image-added="addNewsImage"
        ></vue-editor> <!-- useCustomImageHandler -->
      </div>

      <div class="card-footer d-flex justify-content-between">
        <div>
          <b-button v-if="$route.query.status !== 'new'" variant="danger" @click="deleteNewsContent">
            삭제
          </b-button>
        </div>


        <div>
          <b-button variant="secondary" class="me-2" @click="$router.go(-1)">
            취소
          </b-button>
          <b-button variant="primary" @click="$route.query.status === 'new' ? saveNewsContent() : editNewsContent()">
            {{ $route.query.status === 'new' ? '저장' : '수정' }}
          </b-button>
        </div>
      </div>
    </div>

  </Layout>
</template>
